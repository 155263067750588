<template>
    <el-dialog
        class="assignment_dialog"
        title="选择运营"
        :visible.sync="isShow"
        width="420px"
        @opened="onOpened"
        :before-close="onClose"
    >
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="80px">
            <el-form-item label="选中机会">
                <div class="oppo_container">
                    当前已选中 <span class="num">{{ oppoIds.length }}</span> 条机会
                </div>
            </el-form-item>

            <el-form-item label="分配方式" prop="assignType">
                <el-select @change="onAssignTypeChange" v-model="formData.assignType" filterable placeholder="请选择">
                    <el-option value="auto" label="自动分配" />
                    <el-option value="manual" label="指定分配" />
                    <el-option value="transfer" label="离职交接" />
                </el-select>
            </el-form-item>

            <el-form-item label="运营" prop="staffId" v-if="formData.assignType !== 'auto'">
                <el-select v-model="formData.staffId" filterable placeholder="请选择">
                    <el-option v-for="item in staffList" :key="item.id" :value="item.id" :label="item.name">
                        <StaffName :staff="item" />
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { oppoAssign } from '@/js/api/saleApi.js'
import { getStaffList } from '@/js/api/staffApi.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    props: ['isShow', 'oppoIds'],
    data() {
        return {
            formData: null,
            rules: {
                assignType: [{ required: true, message: '请选择', trigger: 'blur' }],
                staffId: [{ required: true, message: '请选择', trigger: 'blur' }],
            },
            staffList: [],
        }
    },
    methods: {
        onOpened() {
            const formData = {
                assignType: 'auto',
                staffId: '',
            }

            this.formData = formData
            this.getStaffListApi()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onAssignTypeChange() {
            if (this.formData.assignType === 'auto') {
                this.formData.staffId = ''
            }
        },
        getStaffListApi() {
            getStaffList({}).then((res) => {
                this.staffList = res.data
            })
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = { subIds: this.oppoIds, ...this.formData }
                    params = filterQueryEmpty(params)
                    oppoAssign(params).then((res) => {
                        const _aNum = res.data.assigned.length
                        const _unaNum = res.data.unassigned.length
                        this.$message.success(`成功分配：${_aNum}人  未分配：${_unaNum}人`)
                        this.$emit('onClose', true)
                        this.onClose()
                    })
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.oppo_container {
    display: flex;
    align-items: center;
    .num {
        color: red;
        margin: 0 12px;
    }
}
.el-select {
    width: 100%;
}
</style>
