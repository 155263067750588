<template>
    <div class="page_container">
        <div class="query_header">
            <div class="query_conditions">
                <div class="condition_item">
                    <span class="condition_label">项目</span>
                    <span class="condition_content">
                        <el-select
                            v-model="projectNos"
                            filterable
                            clearable
                            multiple
                            collapse-tags
                            placeholder="请选择项目"
                        >
                            <el-option
                                v-for="item in projectList"
                                :key="item.projectNo"
                                :label="item.projectName"
                                :value="item.projectNo"
                            />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">投放渠道</span>
                    <span class="condition_content">
                        <el-cascader
                            v-model="providerNos"
                            :options="providerCascader"
                            placeholder="投放渠道"
                            filterable
                            collapse-tags
                            clearable
                            :props="{ expandTrigger: 'hover', multiple: true }"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">归属设置</span>
                    <span class="condition_content">
                        <el-select v-model="belongFlag" clearable placeholder="请选择">
                            <el-option label="未设置" :value="false" />
                            <el-option label="已设置" :value="true" />
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">加微状态</span>
                    <span class="condition_content">
                        <el-select clearable v-model="wxFriendStates" multiple placeholder="请选择">
                            <el-option
                                v-for="item in addWechatStatusList"
                                :key="item.val"
                                :label="item.name"
                                :value="item.val"
                            />
                            <el-option label="未处理" value="NONE" />
                        </el-select>
                    </span>
                </div>

                <div class="condition_item">
                    <span class="condition_content">
                        <el-input
                            @change="onUserIdChange"
                            placeholder="请输入"
                            clearable
                            v-model.trim="queryTypeValue"
                            @keyup.enter.native="onPaginationChange('page', 1)"
                        >
                            <el-select
                                class="query_type"
                                @change="onQuerySelectChange"
                                v-model="queryType"
                                slot="prepend"
                                placeholder="请选择"
                            >
                                <el-option label="联系方式" value="contact"></el-option>
                                <el-option label="学员ID" value="userId"></el-option>
                                <el-option label="机会ID" value="oppoId"></el-option>
                                <el-option label="微信号" value="wxAccount"></el-option>
                                <el-option label="微信昵称" value="nickname"></el-option>
                                <el-option label="运营备注" value="alias"></el-option>
                                <el-option label="直播带货订单号" value="shopOrderNo"></el-option>
                            </el-select>
                        </el-input>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">表单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="formTime"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">最近表单时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="lastFormTime"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">分配时间</span>
                    <span class="condition_content">
                        <el-date-picker
                            v-model="assignTime"
                            value-format="yyyy-MM-dd"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        />
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">付费状态</span>
                    <span class="condition_content">
                        <el-select v-model="paid" clearable>
                            <el-option :value="true" label="已付费"></el-option>
                            <el-option :value="false" label="未付费"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">联系状态</span>
                    <span class="condition_content">
                        <el-select v-model="effectiveFlag" clearable>
                            <el-option :value="true" label="已联系"></el-option>
                            <el-option :value="false" label="未联系"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="condition_item">
                    <span class="condition_label">运营</span>
                    <span class="condition_content">
                        <StaffCascader
                            ref="staffCascader"
                            :allGroupList="true"
                            :multiple="true"
                            :clearable="true"
                            @change="(val) => (staffIds = val)"
                        />
                    </span>
                </div>
            </div>
            <div class="opt_container">
                <el-button @click="onReset">重置</el-button>
                <el-button @click="onPaginationChange('page', 1)" type="primary">查询</el-button>

                <el-button
                    v-if="P(1020301)"
                    :disabled="activeOppoIds.length === 0"
                    @click="onShowEditOppoStaff"
                    type="primary"
                    plain
                    class="float_left_btn"
                >
                    批量分配 [{{ activeOppoIds.length }}]
                </el-button>
            </div>
        </div>

        <el-table :data="tableData" @selection-change="onSelectionChange" stripe border>
            <el-table-column type="selection" width="55" v-if="P(1020301)" />
            <el-table-column align="center" width="90" prop="id" label="机会ID" />

            <el-table-column align="center" label="项目" width="100">
                <template slot-scope="scope">
                    <span>{{ projectMap[scope.row.projectNo] }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="表单渠道">
                <template slot-scope="scope">
                    <span>{{ providerMap[scope.row.lastProviderNo] || scope.row.lastProviderNo }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="最近表单时间">
                <template slot-scope="scope">
                    <span>{{ scope.row.lastFormTime || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="分配时间">
                <template slot-scope="scope">
                    <span>{{ scope.row.lastAssignTime || '-' }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="center" prop="alias" label="运营备注" />
            <el-table-column align="center" width="100" label="运营">
                <template slot-scope="scope">
                    <StaffName v-if="scope.row.staffId" :staff="scope.row.staff" />
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="加微状态">
                <template slot-scope="scope">
                    <el-tag
                        v-if="addWechatStatusMap[scope.row.wxFriendState]"
                        :type="addWechatStatusMap[scope.row.wxFriendState]['tag']"
                    >
                        {{ addWechatStatusMap[scope.row.wxFriendState]['name'] }}
                    </el-tag>
                    <span v-else>未处理</span>
                </template>
            </el-table-column>
            <el-table-column align="center" width="100" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="onShowDetailDrawer(scope.row)">机会详情</el-link>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            @size-change="(val) => onPaginationChange('size', val)"
            @current-change="(val) => onPaginationChange('page', val)"
            :current-page.sync="page"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50, 1000]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
        />

        <EditOppoStaff :isShow="isShowEditOppoStaff" :oppoIds="activeOppoIds" @onClose="onCloseEditOppoStaff" />
        <DetailDrawer :isShow="isShowDetailDrawer" :activeItem="activeItem" @onClose="onCloseDetailDrawer" />
    </div>
</template>
<script>
import { filterQueryEmpty, isEmptyArray, isUserId } from '@/js/utils.js'
import { getProviderCategoryData } from '@/js/api/advertApi.js'
import { getStaffList } from '@/js/api/staffApi.js'
import { oppoList } from '@/js/api/saleApi.js'
import { addWechatStatusMap, addWechatStatusList } from '@/js/config/opportunit.js'
import StaffCascader from '@/views/components/StaffCascader'
import EditOppoStaff from './components/oppoList/EditOppoStaff.vue'
import DetailDrawer from './workbench/components/tel/DetailDrawer.vue'
import { mapGetters } from 'vuex'

let _localStorageKey = 'oppoList_queryType'
export default {
    computed: {
        ...mapGetters(['projectList']),
        projectMap: function () {
            const _map = {}
            this.projectList.forEach((i) => (_map[i.projectNo] = i.projectName))
            return _map
        },
    },
    components: { EditOppoStaff, DetailDrawer, StaffCascader },
    data() {
        return {
            addWechatStatusMap,
            addWechatStatusList,
            providerMap: null,

            projectNos: '',
            providerNos: [],
            providerCascader: [],
            belongFlag: '',
            wxFriendStates: '',
            queryTypeValue: '',
            queryType: '',
            formTime: [],
            lastFormTime: [],
            assignTime: [],
            paid: '',
            effectiveFlag: '',
            staffIds: '',

            tableData: [],
            page: 1,
            pageSize: 10,
            total: 0,

            activeOppoIds: [],
            isShowEditOppoStaff: false,
            activeItem: null,
            isShowDetailDrawer: false,
        }
    },
    watch: {
        projectList: function () {
            this.onReset()
        },
    },
    created() {
        this.queryType = localStorage.getItem(_localStorageKey) || 'contact'
        this.getProviderCategoryDataApi()
        this.onReset()
    },
    methods: {
        onReset() {
            this.projectNos = ''
            this.providerNos = []
            this.belongFlag = ''
            this.wxFriendStates = ''
            this.queryTypeValue = ''
            this.formTime = []
            this.lastFormTime = []
            this.assignTime = []
            this.paid = ''
            this.effectiveFlag = ''
            this.staffIds = ''
            if (this.$refs.staffCascader) {
                this.$refs.staffCascader.onClear()
            }

            this.onPaginationChange('page', 1)
        },

        onUserIdChange() {
            if (this.queryType === 'userId' && this.queryTypeValue && !isUserId(this.queryTypeValue)) {
                this.queryTypeValue = ''
                this.$message.warning('请输入正确的 学员ID')
            }
        },
        onQuerySelectChange() {
            localStorage.setItem(_localStorageKey, this.queryType)
            this.onUserIdChange()
        },

        onPaginationChange(type, value) {
            if (type === 'size') {
                this.pageSize = value
                this.page = 1
            } else {
                this.page = value
            }
            this.$nextTick(this.oppoListApi)
        },

        onSelectionChange(vals) {
            this.activeOppoIds = vals.map((i) => i.subId)
        },
        // 分配机会
        onShowEditOppoStaff() {
            this.isShowEditOppoStaff = true
        },
        onCloseEditOppoStaff(state) {
            this.activeOppoIds = []
            this.isShowEditOppoStaff = false
            if (state) this.oppoListApi()
        },

        // 机会详情
        onShowDetailDrawer(item) {
            this.activeItem = item
            this.isShowDetailDrawer = true
        },
        onCloseDetailDrawer(status) {
            this.activeItem = null
            this.isShowDetailDrawer = false
            if (status === true) this.oppoListApi()
        },

        async oppoListApi() {
            let params = {
                orderBy: '-id',
                page: this.page,
                size: this.pageSize,
                showPage: true,
                belongFlag: this.belongFlag,
                paid: this.paid,
                effectiveFlag: this.effectiveFlag,
            }

            if (!isEmptyArray(this.staffIds)) {
                params.staffIds = this.staffIds
            }

            if (!isEmptyArray(this.projectNos)) {
                params.projectNos = this.projectNos
            } else if (this.projectList.length !== 0) {
                params.projectNos = this.projectList.map((i) => i.projectNo)
            } else {
                return
            }

            params[this.queryType] = this.queryTypeValue
            params.wxFriendStates = !isEmptyArray(this.wxFriendStates) ? this.wxFriendStates : ''
            if (!isEmptyArray(this.providerNos)) {
                params.providerNos = this.providerNos.map((i) => i[1])
            }
            if (!isEmptyArray(this.formTime)) {
                params.formTimeStart = this.formTime[0]
                params.formTimeEnd = this.formTime[1]
            }
            if (!isEmptyArray(this.lastFormTime)) {
                params.lastFormTimeStart = this.lastFormTime[0]
                params.lastFormTimeEnd = this.lastFormTime[1]
            }
            if (!isEmptyArray(this.assignTime)) {
                params.lastAssignTimeStart = this.assignTime[0]
                params.lastAssignTimeEnd = this.assignTime[1]
            }

            params = filterQueryEmpty(params)
            const res1 = await oppoList(params)
            if (res1.data.list.length !== 0) {
                const _staffIds = []
                res1.data.list.forEach((item) => {
                    if (item.staffId && !_staffIds.includes(item.staffId)) {
                        _staffIds.push(item.staffId)
                    }
                })
                const res2 = await getStaffList({ staffIds: _staffIds })

                const _staffMap = {}
                res2.data.forEach((i) => {
                    _staffMap[i.id] = i
                })
                res1.data.list.forEach((item) => {
                    item.staff = _staffMap[item.staffId]
                })
            }
            this.tableData = res1.data.list
            this.page = res1.data.pageNum
            this.total = res1.data.total
        },

        getProviderCategoryDataApi() {
            getProviderCategoryData().then((res) => {
                this.providerMap = res.providerMap
                this.providerCascader = res.providerCascader
            })
        },
    },
}
</script>
